import React, { useRef, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import makeStyles from '@mui/styles/makeStyles'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Select,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { SWLoadingButtonOutlined } from '@/components/SWLoadingButtonOutlined'
import { SWLoadingButton } from '@/components/LoadingButton'
import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { format } from 'date-fns'
import moment from 'moment-timezone'
import { Unit as UnitType } from '@/Units/types'
import { convertTimestampToTimeZone } from '@/util'
import { useParams } from 'react-router-dom'
import { Bill, ILineItems } from '../types'
import { useBills } from '../useBills'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import NoRows from '@/components/tableNoRows'
import { getUnitStatus } from '@/Structures/util'

dayjs.extend(utc)
dayjs.extend(timezone)

const { RangePicker } = DatePicker

const dateFormat = 'YYYY-MM-DD'

const useStyles = makeStyles({
  paper: {
    minHeight: 560
  }
})

const FormInputWrapper = styled(Box)({
  paddingTop: 8,
  paddingBottom: 8
})

const InputRowWrapper = styled(Box)({
  display: 'flex',
  gap: 24,
  alignItems: 'baseline'
})

type Props = {
  open: boolean
  onClose: () => void
  onSubmit: Function
  start?: number
  end?: number
  cost?: number
  volume?: number
  unit?: 'GALLONS_1K' | 'CCF'
  type: 'ADD' | 'EDIT'
  rateMethod?: 'SIMPLESUB' | 'UTILITY' | 'CUSTOM'
  effectiveRate?: number | null
  loading: boolean
  propertyTimeZone: string
  stripeConnect: boolean
  units: UnitType[]
  volumeUnits: string
}

export interface FormValues {
  startDate: number | null
  endDate: number | null
  volume: number | null
  unit: string
  cost: number | null
  rateMethod: string
  effectiveRate: number | null
  dueDate: number
  fees: { name: string; feeType: string; cost: string }[]
  eUsage: IEstimadeUsage[]
}

const METHOD_DESCRIPTION = {
  SIMPLESUB:
    'This is the recommended rate method if all water to a property is metered by SimpleSUB Water.  The cost of the utility bill will be divided based on usage, so you’ll never over or under bill tenants.  Effective Rate =  Cost / SimpleSUB Measured Total Water Usage',
  UTILITY:
    'This is the recommended rate method if not all water to a property is metered by SimpleSUB Water. An effective rate is calculated based on the water bill. Effective Rate = Cost / Utility Measured Total Water Usage.',
  CUSTOM:
    'Use this method to calculate tenant costs based on a fixed rate from the utility. Warning: Be careful never to overbill for water!'
}

enum Unit {
  GALLONS = 'GALLONS',
  LITERS = 'LITERS'
}

const billingFormValidationSchema = Yup.object().shape({
  startDate: Yup.number().required('Required!'),
  endDate: Yup.number().required('Required!'),
  volume: Yup.number().required('Required!'),
  unit: Yup.string().required('Required!'),
  cost: Yup.number().required('Required!'),
  rateMethod: Yup.string().required('Required!')
})

const today = new Date()

interface IEstimadeUsage {
  deviceId: string
  usage: string
}

export const FormDialog = ({
  start,
  end,
  volume,
  unit,
  cost,
  open,
  type,
  onClose,
  onSubmit,
  rateMethod,
  effectiveRate,
  loading = false,
  propertyTimeZone,
  stripeConnect,
  units,
  volumeUnits
}: Props) => {
  const [openFee, setOpenFee] = React.useState(false)
  const [openMenuFee, setOpenMenuFee] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const fixedElementRef = useRef<HTMLButtonElement | null>(null)

  const handleClickMenu = () => {
    // Asignar siempre el mismo elemento (referencia fija)
    setAnchorEl(fixedElementRef.current)
    setOpenMenuFee(true)
  }

  const [newFeeData, setNewFeeData] = React.useState({ feeType: 'SPLIT', name: '', cost: '', unitId: '' })
  const [newFeeErr, setNewFeeErr] = useState(false)
  const [noDevices, setnoDevices] = useState(false)
  const [estimatedUsage, setEstimatedUsage] = React.useState<IEstimadeUsage[]>([])
  const [steps, setSteps] = React.useState(['Enter Bill', 'Add Fees', 'Estimated Usage'])
  const { structureId } = useParams()
  if (!structureId) {
    throw new Error('MISSING_REQUIRED_ROUTE_DATA')
  }

  // Load bills
  const { bills = [] } = useBills(structureId)

  // Filtrar line items únicos con los tipos 'FLAT' y 'UNIT_FLAT'
  const getUniqueLineItems = (bills: Bill[]) => {
    const uniqueItems = new Map<string, ILineItems>()

    bills.forEach((bill) => {
      bill.lineItems.forEach((item) => {
        if ((item.type === 'FLAT' || item.type === 'UNIT_FLAT') && !uniqueItems.has(item.unit_id || item.description)) {
          uniqueItems.set(item.unit_id || item.description, item)
        }
      })
    })

    return Array.from(uniqueItems.values())
  }

  const filteredLineItems = getUniqueLineItems(bills)

  React.useMemo(() => {
    setSteps(stripeConnect ? ['Enter Bill', 'Add Fees', 'Estimated Usage'] : ['Enter Bill', 'Estimated Usage'])
  }, [stripeConnect, setSteps])

  React.useEffect(() => {
    const initialUsage = units.flatMap((unit) => {
      if (!unit.devices) return []
      return unit.devices
        .filter((device) => device.device_status !== 'OKAY')
        .map((i) => {
          return {
            deviceId: i.id,
            usage: '0.0'
          }
        })
    })

    setEstimatedUsage(initialUsage)
  }, [units])

  React.useEffect(() => {
    const hasDevices = units.some((unit) => unit.devices && unit.devices.length > 0)
    setnoDevices(!hasDevices)
  }, [units])

  const handleUsageChange = (deviceId: string, value: string) => {
    const updatedUsage = estimatedUsage.map((item) => (item.deviceId === deviceId ? { ...item, usage: value } : item))

    setEstimatedUsage(updatedUsage)
  }

  const addDaysToDate = (days: number) => {
    const currentDate = new Date()
    currentDate.setDate(currentDate.getDate() + days)

    const year = currentDate.getFullYear()
    const month = String(currentDate.getMonth() + 1).padStart(2, '0')
    const day = String(currentDate.getDate()).padStart(2, '0')

    return dayjs
      .tz(`${year}-${month}-${day}`, timeZone)
      .set('hours', 23)
      .set('seconds', 59)
      .set('minutes', 59)
      .set('milliseconds', 0)
      .valueOf()
  }
  const browserTimeZone = dayjs.tz.guess()
  const timeZone = propertyTimeZone.trim() === '' ? browserTimeZone : propertyTimeZone
  dayjs.tz.setDefault(timeZone)
  moment.tz.setDefault(timeZone)
  const [activeStep, setActiveStep] = React.useState(0)

  const handleNext = async () => {
    if (type === 'EDIT') return handleSubmit()
    if (!stripeConnect && activeStep !== 2) return setActiveStep(activeStep + 2)
    if (!stripeConnect && activeStep === 2) {
      await formik.setFieldValue('eUsage', estimatedUsage)
      return handleSubmit()
    }
    if (activeStep + 1 !== steps.length) return setActiveStep(activeStep + 1)

    await formik.setFieldValue('eUsage', estimatedUsage)
    await setEstimatedUsage([])
    handleSubmit()
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      startDate: start || null,
      endDate: end || null,
      volume: volume || null,
      unit: unit || 'GALLONS_1K',
      cost: cost || null,
      rateMethod: rateMethod || 'SIMPLESUB',
      effectiveRate: effectiveRate || 0,
      dueDate: addDaysToDate(21),
      fees: [],
      eUsage: []
    },
    onSubmit: async (values) => {
      await onSubmit(values)
      setActiveStep(0)
    },
    validationSchema: billingFormValidationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false
  })
  const classes = useStyles()

  React.useEffect(() => {
    if (open) {
      formik.setValues({
        startDate: start || null,
        endDate: end || null,
        volume: volume || null,
        unit: unit || 'GALLONS_1K',
        cost: cost || null,
        rateMethod: rateMethod || 'SIMPLESUB',
        effectiveRate: effectiveRate || null,
        dueDate: addDaysToDate(21),
        fees: [],
        eUsage: []
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const handleDateChange = (dates) => {
    formik.setFieldValue(
      'startDate',
      dayjs.tz(dates[0], timeZone).set('hours', 0).set('seconds', 0).set('minutes', 0).set('milliseconds', 0).valueOf()
    )
    formik.setFieldValue(
      'endDate',
      dayjs
        .tz(dates[1], timeZone)
        .set('hours', 23)
        .set('seconds', 59)
        .set('minutes', 59)
        .set('milliseconds', 0)
        .valueOf()
    )
  }

  const handleDueDateChange = (date) => {
    formik.setFieldValue(
      'dueDate',
      dayjs.tz(date, timeZone).set('hours', 23).set('seconds', 59).set('minutes', 59).set('milliseconds', 0).valueOf()
    )
  }

  const handleSubmit = async () => {
    const errors = await formik.validateForm()

    if (
      Object.keys(errors).length > 0 ||
      (formik.values.rateMethod === 'CUSTOM' && formik.values.effectiveRate === null)
    ) {
      setActiveStep(0)
      return
    }

    formik.submitForm()
  }

  const handleAddFee = (data?, fee?) => {
    if (fee) {
      formik.setFieldValue('fees', [...formik.values.fees, data])
      setOpenMenuFee(false)
      return
    }
    if (!fee && data.unitId) {
      formik.setFieldValue('fees', [...formik.values.fees, data])
      setOpenMenuFee(false)
      return
    }
    if (
      newFeeData.cost === '' ||
      newFeeData.name === '' ||
      (newFeeData.feeType === 'UNIT_FLAT' && newFeeData.unitId === '')
    )
      return setNewFeeErr(true)

    formik.setFieldValue('fees', [...formik.values.fees, newFeeData])
    setNewFeeErr(false)
    setNewFeeData({ feeType: 'SPLIT', name: '', cost: '', unitId: '' })
    setOpenFee(false)
  }

  const handleRemoveFee = (feeToRemove: { feeType: string; name: string; cost: string }) => {
    const fees = formik.values.fees

    const indexToRemove = fees.findIndex(
      (fee) => fee.feeType === feeToRemove.feeType && fee.name === feeToRemove.name && fee.cost === feeToRemove.cost
    )

    if (indexToRemove === -1) return

    const updatedFees = [...fees.slice(0, indexToRemove), ...fees.slice(indexToRemove + 1)]

    formik.setFieldValue('fees', updatedFees)
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
        maxWidth={'md'}
        fullWidth
        classes={{
          paper: classes.paper
        }}
      >
        <Stack direction={'column'} alignItems={'center'} gap={1}>
          <DialogTitle id="form-dialog-title" fontSize={'24px'} fontWeight={700}>
            {type === 'EDIT' ? 'Edit' : 'Add'} Water Utility Bill
          </DialogTitle>
          {type !== 'EDIT' && (
            <Stepper activeStep={activeStep} alternativeLabel sx={{ width: 400 }}>
              {steps.map((label) => (
                <Step
                  key={label}
                  sx={{
                    '.css-9sr458-MuiSvgIcon-root-MuiStepIcon-root': {
                      color: 'transparent !important',
                      borderColor: '#A1AEBE !important',
                      border: 2,
                      borderRadius: '100%',
                      width: 32,
                      height: 32
                    },

                    '.css-9sr458-MuiSvgIcon-root-MuiStepIcon-root.Mui-active': {
                      color: 'transparent !important',
                      borderColor: '#4890CC !important',
                      border: 2,
                      borderRadius: '100%',
                      width: 32,
                      height: 32
                    },
                    '.css-1hv8oq8-MuiStepLabel-label.Mui-active': { color: '#4890CC !important' },
                    '.css-9sr458-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed': {
                      color: '#4890CC !important'
                    }
                  }}
                >
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          )}
          {type !== 'EDIT' && (
            <>
              <Typography marginLeft={3} fontSize={'14px'} fontWeight={400}>
                {activeStep === 0 ? (
                  <p style={{ textAlign: 'center' }}>
                    Enter your water utility bill. The total cost and water usage will be allocated across your
                    property.
                  </p>
                ) : activeStep === 1 ? (
                  <p style={{ textAlign: 'center' }}>
                    Add any fixed fees you want to appear on all of your tenant invoices. This could be billing &
                    maintance fees, infrastructure fees, sewer charges, etc.
                  </p>
                ) : (
                  <p style={{ textAlign: 'center' }}>
                    The meters listed below are missing data. We suggest entering estimated usage based on previous
                    data. The average water usage per day per person is around 50 gallons. Your tenants will see this
                    estimated usage amount on their invoice
                  </p>
                )}
              </Typography>
            </>
          )}
        </Stack>
        <DialogContent>
          {activeStep === 0 ? (
            <>
              <Typography fontSize={'16px'} fontWeight={700}>
                Utility Bill Information
              </Typography>
              <Divider variant="fullWidth" sx={{ borderWidth: 1, borderColor: 'black' }} />
              <FormInputWrapper marginTop={3}>
                <Typography fontSize="16px" fontWeight={400} marginBottom={1}>
                  Billing Period
                </Typography>
                <RangePicker
                  format={'MMMM D, YYYY'}
                  maxDate={dayjs(format(today, 'yyyy-MM-dd'), dateFormat)}
                  onChange={(value, dateString) => handleDateChange(dateString)}
                  defaultValue={
                    formik.values.startDate && formik.values.endDate
                      ? [dayjs.tz(formik.values.startDate, timeZone), dayjs.tz(formik.values.endDate, timeZone)]
                      : undefined
                  }
                />
                {(formik.errors.startDate || formik.errors.endDate) && (
                  <Typography fontSize="16px" fontWeight={400} variant="body2" sx={{ color: '#e53935' }}>
                    This field is required
                  </Typography>
                )}
              </FormInputWrapper>
              <FormInputWrapper>
                <Typography fontSize="16px" fontWeight={400}>
                  Water Usage (also called Consumption)
                </Typography>
                <InputRowWrapper>
                  <Stack>
                    <TextField
                      margin="dense"
                      name="volume"
                      type="number"
                      placeholder="Volume"
                      value={formik.values.volume}
                      onChange={formik.handleChange}
                      variant="standard"
                      sx={{ width: 180 }}
                    />
                    {formik.errors.volume && (
                      <Typography fontSize="16px" fontWeight={400} variant="body2" sx={{ color: '#e53935' }}>
                        This field is required
                      </Typography>
                    )}
                  </Stack>

                  <FormControl variant="standard" margin="dense" sx={{ minWidth: 180 }}>
                    <Select value={formik.values.unit} name="unit" onChange={formik.handleChange}>
                      <MenuItem value="GALLONS_1K">1000 Gallons</MenuItem>
                      <MenuItem value="CCF">CCF (Centum Cubic Feet)</MenuItem>
                    </Select>
                  </FormControl>
                </InputRowWrapper>
              </FormInputWrapper>
              <FormInputWrapper>
                <Typography fontSize="16px" fontWeight={400}>
                  Cost
                </Typography>
                <InputRowWrapper>
                  <Stack>
                    <TextField
                      margin="none"
                      type="number"
                      name="cost"
                      value={formik.values.cost}
                      onChange={formik.handleChange}
                      variant="standard"
                      sx={{ width: 180 }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{'$'}</InputAdornment>
                      }}
                    />
                    {formik.errors.cost && (
                      <Typography fontSize="16px" fontWeight={400} variant="body2" sx={{ color: '#e53935' }}>
                        This field is required
                      </Typography>
                    )}
                  </Stack>
                  <Typography fontSize="16px" fontWeight={400} variant="body2" color={'#A8A8A8'}>
                    Enter the amount of utility bill to split among the tenants. Typically the total cost.
                  </Typography>
                </InputRowWrapper>
              </FormInputWrapper>
              <Typography fontSize={'16px'} fontWeight={700} marginTop={3}>
                Tenant Invoice Details
              </Typography>
              <Divider variant="fullWidth" sx={{ borderWidth: 1, borderColor: 'black' }} />
              {stripeConnect && (
                <FormInputWrapper marginTop={3}>
                  <Typography fontSize="16px" fontWeight={400}>
                    Due Date
                  </Typography>
                  <InputRowWrapper>
                    <DatePicker
                      defaultValue={formik.values.dueDate ? dayjs.tz(formik.values.dueDate, timeZone) : undefined}
                      onChange={(value, dateString) => handleDueDateChange(dateString)}
                      style={{
                        borderBottomWidth: '1px',
                        borderLeft: 'none',
                        borderRight: 'none',
                        borderTop: 'none',
                        borderRadius: '0',
                        borderColor: 'black'
                      }}
                      disabled
                    />
                    <Typography fontSize="16px" fontWeight={400} variant="body2" color={'#A8A8A8'}>
                      The due date will be set 21 days after the bill is created
                    </Typography>
                  </InputRowWrapper>
                </FormInputWrapper>
              )}
              <FormInputWrapper>
                <Typography fontSize="16px" fontWeight={400}>
                  Rate Method
                </Typography>
                <InputRowWrapper>
                  <FormControl margin="dense" variant="standard" sx={{ minWidth: 180 }}>
                    <Select
                      value={formik.values.rateMethod}
                      variant="standard"
                      name="rateMethod"
                      onChange={formik.handleChange}
                    >
                      <MenuItem value="SIMPLESUB">SimpleSUB Effective</MenuItem>
                      <MenuItem value="UTILITY">Utility Effective</MenuItem>
                      <MenuItem value="CUSTOM">Custom</MenuItem>
                    </Select>
                  </FormControl>
                  <Typography fontSize="16px" fontWeight={400} variant="body2" color={'#A8A8A8'}>
                    {METHOD_DESCRIPTION[formik.values.rateMethod]}
                  </Typography>
                </InputRowWrapper>
              </FormInputWrapper>
              {formik.values.rateMethod === 'CUSTOM' && (
                <FormInputWrapper>
                  <Typography fontSize="16px" fontWeight={400}>
                    Bill Rate
                  </Typography>
                  <InputRowWrapper>
                    <Stack>
                      <TextField
                        margin="none"
                        type="number"
                        name="effectiveRate"
                        value={formik.values.effectiveRate}
                        onChange={formik.handleChange}
                        variant="standard"
                        sx={{ width: 180 }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">{'$'}</InputAdornment>,
                          endAdornment: <InputAdornment position="end">/ 1 {Unit[formik.values.unit]}</InputAdornment>
                        }}
                      />
                      {formik.values.rateMethod === 'CUSTOM' && formik.values.effectiveRate === null && (
                        <Typography fontSize="16px" fontWeight={400} variant="body2" sx={{ color: '#e53935' }}>
                          This field is required
                        </Typography>
                      )}
                    </Stack>
                  </InputRowWrapper>
                </FormInputWrapper>
              )}
            </>
          ) : activeStep === 1 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Fee</TableCell>
                    <TableCell align="right">Cost</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formik.values.fees.map((e) => (
                    <>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {e.name}
                        </TableCell>
                        <TableCell component="th" scope="row" align="right">
                          ${e.cost}
                          <IconButton onClick={() => handleRemoveFee(e)}>
                            <RemoveCircleIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                  {formik.values.fees.length === 0 && (
                    <TableRow>
                      <TableCell sx={{ paddingLeft: 20 }}>
                        <NoRows title="Start adding fees" />
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <Box sx={{ cursor: 'pointer', color: '#4890CC' }} ref={fixedElementRef} onClick={handleClickMenu}>
                        Add Fee +
                      </Box>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={openMenuFee}
                        onClose={() => setOpenMenuFee(false)}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button'
                        }}
                      >
                        {filteredLineItems &&
                          filteredLineItems.map((i: any) => {
                            return (
                              <MenuItem
                                key={i.id}
                                onClick={() => {
                                  if (i.unit_id)
                                    return handleAddFee(
                                      { name: i.description, cost: i.cost, feeType: i.type, unitId: i.unit_id },
                                      false
                                    )
                                  handleAddFee({ name: i.description, cost: i.cost, feeType: i.type }, true)
                                }}
                              >
                                {i.description}
                              </MenuItem>
                            )
                          })}
                        <MenuItem
                          onClick={() => {
                            setOpenMenuFee(false)
                            setOpenFee(true)
                          }}
                        >
                          Add other fee
                        </MenuItem>
                      </Menu>
                    </TableCell>
                    <TableCell component="th" scope="row" align="right"></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <caption>{noDevices && <NoRows title="All devices OK" />}</caption>
                <TableHead>
                  <TableRow>
                    <TableCell>Unit Name</TableCell>
                    <TableCell>Device</TableCell>
                    <TableCell>Last Meter Read</TableCell>
                    <TableCell>Estimated Usage</TableCell>
                    <TableCell>Volume Unit</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {units.map((unit) =>
                    unit.devices.map((device) => {
                      if (device.device_status !== 'OKAY') {
                        return (
                          <TableRow key={unit.id}>
                            <TableCell>{unit.name}</TableCell>
                            <TableCell>{device.name}</TableCell>
                            <TableCell>
                              {convertTimestampToTimeZone(device.last_seen || 0, timeZone, false, true)}
                            </TableCell>
                            <TableCell>
                              <TextField
                                variant="standard"
                                onChange={(e) => handleUsageChange(device.id, e.target.value.toString())}
                                type="number"
                              />
                            </TableCell>
                            <TableCell>{volumeUnits}</TableCell>
                          </TableRow>
                        )
                      }
                      return ''
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
        <DialogActions>
          <SWLoadingButtonOutlined
            onClick={() => {
              if (activeStep === 0) {
                setActiveStep(0)
                return onClose()
              }
              if (!stripeConnect) return setActiveStep(activeStep - 2)
              setActiveStep(activeStep - 1)
            }}
          >
            {activeStep === 0 ? 'Cancel' : 'Back'}
          </SWLoadingButtonOutlined>
          <SWLoadingButton onClick={handleNext} loading={loading}>
            {type === 'EDIT'
              ? 'Save'
              : !stripeConnect && activeStep === 2
              ? 'Submit'
              : activeStep !== steps.length - 1
              ? 'Next'
              : 'Add Bill'}
          </SWLoadingButton>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openFee}
        onClose={() => setOpenFee(false)}
        aria-labelledby="form-dialog-title"
        maxWidth={'sm'}
        fullWidth
      >
        <Stack direction={'column'} alignItems={'center'} gap={1}>
          <DialogTitle id="form-dialog-title" fontSize={'24px'} fontWeight={700}>
            Add Fee
          </DialogTitle>
        </Stack>
        <DialogContent>
          <Stack gap={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Type Fee</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={newFeeData.feeType}
                label="Type Fee"
                onChange={(e) => setNewFeeData({ ...newFeeData, feeType: e.target.value })}
              >
                <MenuItem value={'SPLIT'}>Split</MenuItem>
                <MenuItem value={'FLAT'}>Flat</MenuItem>
                <MenuItem value={'UNIT_FLAT'}>Unit Flat</MenuItem>
              </Select>
            </FormControl>
            {newFeeData.feeType === 'UNIT_FLAT' && (
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={newFeeData.unitId}
                  label="Unit"
                  onChange={(e) => setNewFeeData({ ...newFeeData, unitId: e.target.value })}
                  error={newFeeErr && newFeeData.unitId === ''}
                >
                  {units &&
                    units
                      .filter(Boolean)
                      .sort((a, b) => {
                        const statusOrder = { ERROR: 1, WARNING: 2, OKAY: 3, NO_DEVICES: 3 }
                        const statusA = getUnitStatus(a)
                        const statusB = getUnitStatus(b)
                        if (statusOrder[statusA] !== statusOrder[statusB]) {
                          return statusOrder[statusA] - statusOrder[statusB]
                        }
                        return a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })
                      })
                      .map((i) => {
                        return <MenuItem value={i.id}>{i.name}</MenuItem>
                      })}
                </Select>
              </FormControl>
            )}
            <TextField
              fullWidth
              id="outlined-basic"
              label="Fee Name"
              variant="outlined"
              value={newFeeData.name}
              onChange={(e) => setNewFeeData({ ...newFeeData, name: e.target.value })}
              error={newFeeErr && newFeeData.name === ''}
              helperText={newFeeErr && newFeeData.name === '' ? 'Required' : ''}
            />
            <TextField
              fullWidth
              id="outlined-basic"
              label="Cost"
              variant="outlined"
              value={newFeeData.cost}
              onChange={(e) => setNewFeeData({ ...newFeeData, cost: e.target.value.toString() })}
              type="number"
              error={newFeeErr && newFeeData.cost === ''}
              helperText={newFeeErr && newFeeData.cost === '' ? 'Required' : ''}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <SWLoadingButtonOutlined onClick={() => setOpenFee(false)}>Cancel</SWLoadingButtonOutlined>
          <SWLoadingButton onClick={handleAddFee} loading={loading}>
            Add Fee
          </SWLoadingButton>
        </DialogActions>
      </Dialog>
    </>
  )
}
