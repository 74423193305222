/** @file Dialog to add a single unit */
import { FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material'
import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import type { PipeDiameter, PipeMaterial } from '../types'
import { SWLoadingButton } from '@/components/LoadingButton'

type Props = {
  name: string
  pipeDiameter: PipeDiameter
  pipeMaterial: PipeMaterial
  open: boolean
  onClose: () => void
  addOrEdit: 'ADD' | 'EDIT'
  type: 'UNIT' | 'COMMON_AREA'
  onSubmit: (data: {
    name: string
    pipeDiameter: PipeDiameter
    pipeMaterial: PipeMaterial
    unitFlag?: 'NON_BILLABLE' | 'COMMON_AREA' | 'SPLIT'[]
  }) => void
  loading: boolean
}

/**
 * Create Unit dialog
 *
 * @param {object} props - Component properties
 * @returns {React.Component} - Create Unit dialog
 */
export const FormDialog = (props: Props) => {
  const [localName, setLocalName] = React.useState(props.name)
  const [unitFlag, setUnitFlag] = React.useState<'NON_BILLABLE' | 'COMMON_AREA' | 'SPLIT'>('SPLIT')

  const hadleSubmit = () => {
    let body = {
      name: localName,
      pipeDiameter: props.pipeDiameter,
      pipeMaterial: props.pipeMaterial
    }
    if (props.type === 'COMMON_AREA') body['unitFlag'] = ['COMMON_AREA', unitFlag]
    console.log(body)

    props.onSubmit(body)
  }

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle>{`${props.addOrEdit === 'ADD' ? 'Add' : 'Edit'} ${
        props.type === 'UNIT' ? 'Unit' : 'Common Area'
      }`}</DialogTitle>
      <DialogContent>
        <Stack gap={2}>
          {props.name !== undefined && (
            <TextField
              label={'Name'}
              placeholder={'Name'}
              helperText={'eg: "1", "205-b"'}
              required={true}
              value={localName}
              sx={{ marginTop: 2 }}
              onChange={(e) => {
                setLocalName(e.target.value)
              }}
            />
          )}
          {props.type === 'COMMON_AREA' && (
            <FormControl fullWidth>
              <InputLabel id="unitflag">Bill Model</InputLabel>
              <Select
                labelId="unitflag"
                value={unitFlag}
                label="Bill Model"
                onChange={(e) => setUnitFlag(e.target.value as 'NON_BILLABLE' | 'COMMON_AREA' | 'SPLIT')}
              >
                <MenuItem value={'SPLIT'}>Split</MenuItem>
                <MenuItem value={'NON_BILLABLE'}>Non Billable</MenuItem>
              </Select>
            </FormControl>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <SWLoadingButton onClick={hadleSubmit} loading={props.loading}>
          {props.addOrEdit === 'ADD' ? 'Add' : 'Save'}
        </SWLoadingButton>
      </DialogActions>
    </Dialog>
  )
}
