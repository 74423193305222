import { DeleteConfirmDialog } from '@/components/Dialog'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import makeStyles from '@mui/styles/makeStyles'
import { useState } from 'react'
import 'react-day-picker/dist/style.css'
import { useNavigate, useParams } from 'react-router-dom'
import { ErrorBoundary } from '../../components/ErrorBoundary'
import type { Address as AddressType } from '../../types'
import { UtilityWaterBills } from '../UtilityWaterBills'
import { edit, remove } from '../api'
import { useStructure } from '../useStructures'
import { Address } from './Address'
import { Name } from './Name'
import { AllDevices } from '../AllDevices'
import { UsageTab } from '../UtilityWaterBills/usage'
import { SubscribersView } from '../subscribers'
import React from 'react'
import { getUserInfo } from '@/pages/AccountSettings/api'
import { FeesView } from '../fees'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    width: '100%'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  header: {
    alignItems: 'baseline',
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(3),
    paddingBottom: 0,
    paddingLeft: 0,
    textAlign: 'left'
  },
  chartBody: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
  },
  chartLoader: {
    left: '20%',
    position: 'absolute',
    top: '50%',
    width: '60%'
  },
  selectedDays: {
    fontWeight: 'bold',
    backgroundColor: theme.palette.secondary.main
  },
  editIcon: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(-0.5),
    opacity: 0.3,
    '&:hover': {
      opacity: 1
    }
  },
  prevNextButton: {
    height: 'fit-content'
  },
  nextButton: {
    marginLeft: theme.spacing(1)
  },
  prevButton: {
    marginRight: theme.spacing(1)
  }
}))

export const Structure = () => {
  const [value, setValue] = useState('1')
  const [loading, setLoading] = useState(false)
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false)
  const classes = useStyles()
  const navigate = useNavigate()
  const [stripeConnect, setStripeConnect] = React.useState<boolean | undefined>(undefined)

  React.useEffect(() => {
    const getData = async () => {
      const userInfo = await getUserInfo()
      if (userInfo) {
        setStripeConnect(
          userInfo.user.stripe_connected_account_id !== '' && userInfo.user.stripe_connected_account_details_submitted
            ? true
            : false
        )
      }
    }
    if (stripeConnect === undefined) getData()
  }, [stripeConnect])

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  const { structureId } = useParams()
  if (!structureId) {
    throw new Error('MISSING_REQUIRED_ROUTE_DATA')
  }
  const { structure, mutate: updateStructure } = useStructure(structureId)

  const handleUpdateStructure = (fieldName: string | string[]) => async (value: string | any[] | AddressType) => {
    if (structure) {
      setLoading(true)

      if (typeof fieldName === 'string') {
        const newStructure = { ...structure, [fieldName]: value }
        updateStructure(newStructure, false)
        await edit(newStructure)

        await updateStructure()

        setLoading(false)
      } else if (Array.isArray(fieldName)) {
        const newFields = {}

        for (let x = 0; x < fieldName.length; x++) {
          newFields[fieldName[x]] = value[x]
        }

        const newStructure = { ...structure, ...newFields }
        await edit(newStructure)

        await updateStructure()

        setLoading(false)
      }
    }
  }

  const handleDeleteConfirm = () => {
    if (!structure) return
    setLoading(true)
    remove(structure.id)
      .then(() => {
        navigate('/')
        updateStructure()
      })
      .finally(() => {
        setLoading(false)
        setOpenDeleteConfirm(false)
      })
  }

  const handleClickDeleteStructure = () => {
    setOpenDeleteConfirm(true)
  }

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <ErrorBoundary>
        <div data-component-id={'STRUCTURE-VIEW'} className={classes.wrapper}>
          {structure && (
            <div className={classes.container}>
              <div className={classes.header}>
                <div style={{ flexGrow: 1 }}>
                  <Name
                    name={structure.name}
                    onUpdate={handleUpdateStructure('name')}
                    onDelete={handleClickDeleteStructure}
                  />
                  <Address
                    address={structure.address}
                    timeZone={structure.timezone_key}
                    onUpdate={handleUpdateStructure(['address', 'timezone_key'])}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </ErrorBoundary>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Billing" value="1" />
            <Tab label="Usage" value="3" />
            <Tab label="Devices" value="2" />
            <Tab label="Tenants" value="4" />
            {stripeConnect && <Tab label="Fees" value="5" />}
          </TabList>
        </Box>
        <TabPanel value="1">
          <UtilityWaterBills stripeConnect={stripeConnect !== undefined ? stripeConnect : false} />
        </TabPanel>
        <TabPanel value="2">
          <AllDevices />
        </TabPanel>
        <TabPanel value="3">{structure && <UsageTab timeZone={structure.timezone_key} />}</TabPanel>
        <TabPanel value="4">
          <SubscribersView stripeConnect={stripeConnect !== undefined ? stripeConnect : false} />
        </TabPanel>
        <TabPanel value="5">
          <FeesView stripeConnect={stripeConnect !== undefined ? stripeConnect : false} />
        </TabPanel>
      </TabContext>
      <DeleteConfirmDialog
        open={openDeleteConfirm}
        onClose={() => setOpenDeleteConfirm(false)}
        onConfirm={handleDeleteConfirm}
        loading={loading}
        title="Do you really want to delete this property?"
      />
    </Box>
  )
}
