import * as React from 'react'
import {
  Box,
  Checkbox,
  Chip,
  Dialog,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ErrorBoundary } from '../../../components/ErrorBoundary'
import { OrderIcon } from '@/components/OrderIcon'
import { SWLoadingButton } from '@/components/LoadingButton'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { FormDialogSubscriber } from '../form'
import { Unit } from '@/Units/types'
import { ISubscibersBody, ISubscriber } from '../types'
import { deleteSubscribers, editSubscriber, putSubscribers, updateBillingContact } from '../api'
import { IFormData } from '../form/types'
import { FormBulkSubscriber } from '../form/bulk'
import useMixPanel from '@/hooks/useMixPanel'
import { Structure } from '@/Structures/types'
import { SWLoadingButtonOutlined } from '@/components/SWLoadingButtonOutlined'
import InfoIcon from '@mui/icons-material/Info'
import { DatePicker } from 'antd'
import moment from 'moment'
import dayjs from 'dayjs'

export interface IUpdateContactRequest {
  new_billing_contact_subscriber_id?: string
  new_billing_contact_move_in_date?: string
  old_billing_contact_subscriber_id?: string
  old_billing_contact_move_out_date?: string
}

type SortDirection = 'asc' | 'desc'

type SortOrder = -1 | 0 | 1

type SortableProperty = 'name' | 'start' | 'end' | 'volume' | 'cost'

type RowData = {
  id: string
  unit_name: string
  unit_id: string
  email: string
  first_name: string
  last_name: string
  isBillingContact: boolean
  by_default: boolean
  moveInDate: string
  moveOutDate: string
  hasOpenInvoices: boolean
}

type Comparator = (a: RowData, b: RowData, orderBy?: SortableProperty) => SortOrder

interface IUnitSubscription {
  subscriber_id: string
  unit_id: string
  unit_name: string
  is_billing_contact: boolean
  move_in_date: null | string
  move_out_date: null | string
  has_open_invoices: boolean
  open_stripe_invoice_ids: any[]
}

interface ISubscribers {
  id: string
  email: string
  first_name: string
  last_name: string
  unit_subscriptions: IUnitSubscription[]
}

// Util ///////////////////////////////////////////////////////////////////////

/**
 * Sort in descending order
 *
 * @param {object} a - First object to compare
 * @param {object} b - Second object to compare
 * @param {string} orderBy - Property name to order by
 *
 * @returns {-1 | 0 | 1} - Sort order
 */
const descendingComparator = (a: RowData, b: RowData, orderBy: SortableProperty) => {
  // $FlowFixMe
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  // $FlowFixMe
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

/**
 * Get sorting function
 *
 * @param {'asc' | 'desc'} order - Direction to sort
 * @param {*} orderBy - name to order by
 *
 * @returns {Function} - Function to sort with
 */
const getComparator = (order: SortDirection, orderBy: SortableProperty): Comparator => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy) as SortOrder
    : (a, b) => -descendingComparator(a, b, orderBy) as SortOrder
}

/**
 * Sort row data
 *
 * @param {RowData[]} array - Array of RowData
 * @param {Function} comparator - Comparing/sorting function
 *
 * @returns {RowData[]} - Sorted RowData
 */

const hiddenArrow = () => <span />
const stableSort = (array: RowData[], comparator: Comparator): RowData[] => {
  const stabilizedThis = array.map((el, index) => [el, index])

  stabilizedThis.sort((a, b) => {
    // $FlowFixMe
    const order = comparator(a[0] as RowData, b[0] as RowData)

    if (order !== 0) return order

    return (a as number[])[1] - (b as number[])[1]
  })

  return stabilizedThis.map((el) => el[0] as RowData)
}

/**
 * Convert data to table rows
 *
 * @param  {object[]} suscribersArr - Array of Bills
 * @returns {object[]} - Array of rows
 */
const subscribersToRows = (subscribersArr: ISubscribers[]): RowData[] => {
  // Agrupar suscriptores por unit_id
  const unitGroups: Record<string, ISubscribers[]> = {}

  subscribersArr.forEach((subscriber) => {
    subscriber.unit_subscriptions.forEach((unit) => {
      if (!unitGroups[unit.unit_id]) {
        unitGroups[unit.unit_id] = []
      }
      unitGroups[unit.unit_id].push(subscriber)
    })
  })

  const rows: RowData[] = []

  // Procesar cada grupo de unidad
  Object.keys(unitGroups).forEach((unit_id) => {
    const subscribersForUnit = unitGroups[unit_id]

    // Verificar si existe un contacto de facturación en la unidad
    const hasBillingContact = subscribersForUnit.some((subscriber) =>
      subscriber.unit_subscriptions.some((unit) => unit.unit_id === unit_id && unit.is_billing_contact)
    )

    // Si no hay contacto de facturación, marcar al primer suscriptor como por defecto
    subscribersForUnit.forEach((subscriber, index) => {
      const unit = subscriber.unit_subscriptions.find((u) => u.unit_id === unit_id)

      if (unit) {
        const row: RowData = {
          id: subscriber.id,
          unit_name: unit.unit_name,
          unit_id: unit.unit_id,
          email: subscriber.email,
          first_name: subscriber.first_name,
          last_name: subscriber.last_name,
          isBillingContact: unit.is_billing_contact,
          hasOpenInvoices: unit.has_open_invoices,
          by_default: false, // Inicialmente en false para todos
          moveInDate: unit.move_in_date || '',
          moveOutDate: unit.move_out_date || ''
        }

        // Marcar el primer suscriptor como "by_default" si no hay contacto de facturación
        if (!hasBillingContact && index === 0) {
          row.by_default = true
        }

        rows.push(row)
      }
    })
  })

  return rows
}

// Components /////////////////////////////////////////////////////////////////

// Head ///////////////////////////////

const headCells = [
  { id: 'unit_name', numeric: false, disablePadding: false, label: 'Unit Name' },
  { id: 'email', numeric: false, disablePadding: false, label: 'Email Address' },
  { id: 'first_name', numeric: false, disablePadding: false, label: 'First Name' },
  { id: 'last_name', numeric: false, disablePadding: false, label: 'Last Name' }
]

type HeadProps = {
  classes: { [x: string]: string }
  order: SortDirection
  orderBy: SortableProperty
  onRequestSort: (event: React.MouseEvent, property: SortableProperty) => void
  stripeConnect: boolean
}

const EnhancedTableHead = ({ classes, order, orderBy, onRequestSort, stripeConnect }: HeadProps) => {
  const createSortHandler = (property: SortableProperty) => (event: React.MouseEvent) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell align={'justify'}></TableCell>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align="center" sortDirection={false}>
            <TableSortLabel IconComponent={hiddenArrow} onClick={createSortHandler(headCell.id as SortableProperty)}>
              {headCell.label}
              {orderBy === headCell.id && (
                <span
                  style={{
                    position: 'absolute',
                    right: '-25px'
                  }}
                >
                  <OrderIcon type={order} />
                </span>
              )}
            </TableSortLabel>
          </TableCell>
        ))}
        {stripeConnect && (
          <TableCell align={'center'}>
            Occupancy Dates{' '}
            <Tooltip
              title="Adding an occupancy date ensures tenants are billed only for the time they occupy the unit. Without this date, tenants will be charged the full amount."
              placement="top-end"
            >
              <IconButton>
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </TableCell>
        )}
        {stripeConnect && <TableCell align={'center'}>Invoice Contact</TableCell>}
        <TableCell align={'justify'}></TableCell>
      </TableRow>
    </TableHead>
  )
}

const EnhancedTableToolbar = ({ onAdd, onBulkAdd }) => {
  return (
    <Box margin={3}>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-end" spacing={0}>
        <Typography variant="h6" id="tableTitle" component="div"></Typography>
        <Box>
          <SWLoadingButton onClick={onAdd} sx={{ ml: 2 }}>
            Add Tenant
          </SWLoadingButton>
          <SWLoadingButton onClick={onBulkAdd} sx={{ ml: 2 }}>
            Import
          </SWLoadingButton>
        </Box>
      </Stack>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: `${theme.spacing(4)} 0 ${theme.spacing(3)}`
  },
  paper: {
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 650
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}))

type Props = {
  subscribers: ISubscribers[]
  units: Unit[]
  setNeedLoadData: Function
  structure: Structure
  stripeConnect: boolean
}

interface ISelectedSubscibersInfo {
  id: string
  UnitID: string
  UnitName: string
}

export const SuscribersTable = ({
  subscribers,
  units,
  setNeedLoadData,
  structure,
  stripeConnect
}: Props): JSX.Element => {
  const [order, setOrder] = React.useState<SortDirection>('desc')
  const [orderBy, setOrderBy] = React.useState<SortableProperty>('start')
  const [page, setPage] = React.useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(5)
  const [isAddFormOpen, setIsAddFormOpen] = React.useState<boolean>(false)
  const [isEditFormOpen, setIsEditFormOpen] = React.useState<boolean>(false)
  const [isBulkFormOpen, setIsBulkFormOpen] = React.useState<boolean>(false)
  const [subscriberToEdit, setSubscriberToEdit] = React.useState<IFormData>()
  const [selectedSubscriber, setSelectedSubscriber] = React.useState<RowData>()
  const [selected, setSelected] = React.useState<readonly string[]>([])
  const [selectedInfo, setSelectedInfo] = React.useState<ISelectedSubscibersInfo[]>([])
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [propertyInfo] = React.useState({ PropertyID: structure.id, PropertyName: structure.name })
  const { trackEvent } = useMixPanel()
  const [openDelete, setOpenDelete] = React.useState<boolean>(false)
  const [openSubInvoice, setOpenSubInvoice] = React.useState<boolean>(false)
  const [openConfirmDate, setOpenConfirmDate] = React.useState<boolean>(false)
  const [typeDate, setTypeDate] = React.useState<'MoveIn' | 'MoveOut'>('MoveOut')
  const [deleteAction, setDeleteAction] = React.useState<() => void>(() => {})
  const [tenantMoveInDate, setTenantMoveInDate] = React.useState<string | null>(null)
  const [tenantMoveOutDate, setTenantMoveOutDate] = React.useState<string | null>(null)
  const [currentBillContact, setCurrentBillContact] = React.useState({
    name: '',
    needMoveOutDate: false,
    moveOutDate: ''
  })

  const dateFormat = 'YYYY-MM-DD'

  const findBillingContactByUnitId = (unitId) => {
    const tenant = subscribers.find((tenant) =>
      tenant.unit_subscriptions.some(
        (subscription) => subscription.unit_id === unitId && subscription.is_billing_contact
      )
    )

    return tenant ? tenant : ''
  }

  const isSelected = (id: string) => selected.indexOf(id) !== -1

  const classes = useStyles()

  const rows: RowData[] = subscribersToRows(subscribers)

  const handleRequestSort = (event: React.MouseEvent, property: SortableProperty) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => setPage(newPage)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const emptyRows: number = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage)

  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null)
  const [menuSelectedId, setMenuSelectedId] = React.useState(null)

  const handleMenuClick = (event, id) => {
    setMenuAnchorEl(event.currentTarget)
    setMenuSelectedId(id)
  }

  const handleMenuClose = () => {
    setMenuAnchorEl(null)
    setMenuSelectedId(null)
  }

  const handleAdd = async (body: ISubscibersBody, unitInfo: { unit_id: string; unit_name: string }) => {
    await putSubscribers(body)
    await trackEvent('Subscriber Invited', { ...propertyInfo, UnitID: unitInfo.unit_id, UnitName: unitInfo.unit_name })
    setNeedLoadData(true)
  }

  const handleAddBulk = async (body: ISubscibersBody, type: string) => {
    await putSubscribers(body)
    await trackEvent('Subscribers Imported', { ...propertyInfo, Count: body.subscribers.length, FileType: type })
    setNeedLoadData(true)
  }

  const handleEdit = async (body: ISubscriber, unitInfo: { unit_id: string; unit_name: string }) => {
    await editSubscriber(body)
    setNeedLoadData(true)
  }

  const handleDeleteSubscriber = async (id: string, unitInfo: { UnitID: string; UnitName: string }) => {
    setIsLoading(true)
    await deleteSubscribers({ subscriber_ids: [id] })
    await trackEvent('Subscriber Deleted', { ...propertyInfo, ...unitInfo })
    setNeedLoadData(true)
    setIsLoading(false)
    setOpenDelete(false)
  }

  const handleDeleteSubscribers = async () => {
    setIsLoading(true)
    await deleteSubscribers({ subscriber_ids: selected })
    await trackEvent('Subscribers Deleted', { ...propertyInfo, ...selectedInfo })
    setSelected([])
    setSelectedInfo([])
    setNeedLoadData(true)
    setIsLoading(false)
    setOpenDelete(false)
  }

  const handleDialogBillingContact = async (row: RowData) => {
    handleMenuClose()
    const bTenant = await findBillingContactByUnitId(row.unit_id)

    if (bTenant !== '' && bTenant.unit_subscriptions[0].move_out_date !== null)
      await setCurrentBillContact({
        ...currentBillContact,
        name: bTenant.first_name + ' ' + bTenant.last_name,
        needMoveOutDate: false,
        moveOutDate: bTenant.unit_subscriptions[0].move_out_date
      })

    if (
      bTenant !== '' &&
      (bTenant.unit_subscriptions[0].move_out_date === null || bTenant.unit_subscriptions[0].move_out_date === '')
    )
      await setCurrentBillContact({
        ...currentBillContact,
        name: bTenant.first_name + ' ' + bTenant.last_name,
        needMoveOutDate: true
      })
    await setSelectedSubscriber(row)
    setOpenSubInvoice(true)
  }

  const handleMoveOut = async () => {
    let request: IUpdateContactRequest = {
      old_billing_contact_move_out_date: tenantMoveOutDate!,
      old_billing_contact_subscriber_id: selectedSubscriber?.id!
    }
    if (typeDate === 'MoveIn')
      request = {
        new_billing_contact_subscriber_id: selectedSubscriber?.id!,
        new_billing_contact_move_in_date: tenantMoveOutDate!
      }
    await updateBillingContact(request, selectedSubscriber?.unit_id!)
    setTenantMoveInDate(null)
    setTenantMoveOutDate(null)
    setCurrentBillContact({ name: '', needMoveOutDate: false, moveOutDate: '' })
    setSelected([])
    setNeedLoadData(true)
    handleMenuClose()
    setIsLoading(false)
    setOpenSubInvoice(false)
    setSelectedSubscriber(undefined)
    setOpenConfirmDate(false)
  }

  const handleUpdateBillingContact = async () => {
    setIsLoading(true)
    const bTenant = findBillingContactByUnitId(selectedSubscriber?.unit_id)

    let request: IUpdateContactRequest = {
      new_billing_contact_move_in_date: tenantMoveInDate!,
      new_billing_contact_subscriber_id: selectedSubscriber?.id!
    }

    if (bTenant !== '' && tenantMoveOutDate !== null)
      request = { ...request, old_billing_contact_move_out_date: tenantMoveOutDate }

    if (
      bTenant !== '' &&
      (bTenant.unit_subscriptions[0].move_out_date !== '' || bTenant.unit_subscriptions[0].move_out_date !== null)
    )
      request = { ...request, old_billing_contact_move_out_date: bTenant.unit_subscriptions[0].move_out_date! }

    if (bTenant !== '') request = { ...request, old_billing_contact_subscriber_id: bTenant.id }

    await updateBillingContact(request, selectedSubscriber?.unit_id!)
    setTenantMoveInDate(null)
    setTenantMoveOutDate(null)
    setCurrentBillContact({ name: '', needMoveOutDate: false, moveOutDate: '' })
    setSelected([])
    setNeedLoadData(true)
    handleMenuClose()
    setIsLoading(false)
    setOpenSubInvoice(false)
    setSelectedSubscriber(undefined)
  }

  const handleOpenEdit = (id: string) => {
    const row = rows.find((i) => i.id === id)
    if (row) {
      const formatData: IFormData = {
        id: row.id,
        email: row.email,
        fist_name: row.first_name,
        last_name: row.last_name,
        unit: { id: row.unit_id, label: row.unit_name }
      }
      setSubscriberToEdit(formatData)
      setIsEditFormOpen(true)
    }
  }

  const handleClickItem = (row: RowData) => {
    const item = selected.find((i) => i === row.id)
    const itemInfo = selectedInfo.find((i) => i.id === row.id)
    if (item === undefined) setSelected([...selected, row.id])
    if (item) {
      const newSelected = selected.filter((item) => item !== row.id)
      setSelected(newSelected)
    }

    if (itemInfo === undefined)
      setSelectedInfo([...selectedInfo, { id: row.id, UnitID: row.unit_id, UnitName: row.unit_name }])
    if (itemInfo) {
      const newSelected = selectedInfo.filter((item) => item.id !== row.id)
      setSelectedInfo(newSelected)
    }
  }

  return (
    <ErrorBoundary>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <EnhancedTableToolbar onAdd={() => setIsAddFormOpen(true)} onBulkAdd={() => setIsBulkFormOpen(true)} />
          <TableContainer>
            <Table className={classes.table} size="medium">
              <EnhancedTableHead
                stripeConnect={stripeConnect}
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody sx={{ position: 'relative' }}>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const inputDate = moment(row.moveOutDate, 'YYYY-MM-DD', true)
                    const today = moment().startOf('day')
                    const canEdit = row.hasOpenInvoices && row.moveOutDate !== ''
                    const out = !row.hasOpenInvoices && row.moveOutDate !== '' && inputDate.isBefore(today)
                    const isItemSelected = isSelected(row.id)
                    const labelId = `enhanced-table-checkbox-${row.id}`

                    return (
                      <TableRow
                        hover
                        key={row.id}
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                        sx={{
                          backgroundColor: out ? '#f5f5f5' : 'inherit', // Fondo gris claro si está deshabilitado
                          opacity: out ? 0.5 : 1 // Atenúa la fila si está deshabilitada
                        }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId
                            }}
                            onClick={() => handleClickItem(row)}
                          />
                        </TableCell>
                        <TableCell align="center">{row.unit_name}</TableCell>
                        <TableCell align="center">{row.email}</TableCell>
                        <TableCell align="center">{row.first_name}</TableCell>
                        <TableCell align="center">{row.last_name}</TableCell>
                        {stripeConnect && (
                          <TableCell align="center">
                            {row.isBillingContact && (
                              <Stack direction={'row'} gap={1}>
                                <DatePicker
                                  placeholder="Move In Date"
                                  format={'MMMM D, YYYY'}
                                  disabled={row.moveOutDate !== ''}
                                  value={dayjs(row.moveInDate)}
                                  onChange={(value, dateString) => {
                                    setSelectedSubscriber(row)
                                    setTenantMoveOutDate(moment(dateString).format(dateFormat))
                                    setTypeDate('MoveIn')
                                    setOpenConfirmDate(true)
                                  }}
                                />
                                {row.moveOutDate !== '' ? (
                                  <DatePicker
                                    placeholder="Move Out Date"
                                    format={'MMMM D, YYYY'}
                                    // maxDate={dayjs(format(today, 'yyyy-MM-dd'), dateFormat)}
                                    value={moment(row.moveOutDate)}
                                    disabled
                                  />
                                ) : (
                                  <DatePicker
                                    placeholder="Move Out Date"
                                    format={'MMMM D, YYYY'}
                                    // maxDate={dayjs(format(today, 'yyyy-MM-dd'), dateFormat)}
                                    onChange={(value, dateString) => {
                                      setSelectedSubscriber(row)
                                      setTenantMoveOutDate(moment(dateString).format(dateFormat))
                                      setTypeDate('MoveOut')
                                      setOpenConfirmDate(true)
                                    }}
                                  />
                                )}
                              </Stack>
                            )}
                            {row.by_default && <></>}
                          </TableCell>
                        )}
                        {stripeConnect && (
                          <TableCell align="center">
                            {row.isBillingContact && row.moveOutDate === '' ? (
                              <Chip
                                label="Tenant Selected"
                                sx={{ backgroundColor: '#EDF4FA', color: '#4890CC', borderRadius: '8px' }}
                              />
                            ) : (
                              ''
                            )}
                            {row.by_default && (
                              <Chip
                                label="Selected by Default"
                                sx={{ backgroundColor: '#EDF4FA', color: '#4890CC', borderRadius: '8px' }}
                              />
                            )}
                          </TableCell>
                        )}
                        <TableCell>
                          <IconButton
                            aria-controls={`menu-${row.id}`}
                            aria-haspopup="true"
                            onClick={(event) => handleMenuClick(event, row.id)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            id={`menu-${row.id}`}
                            anchorEl={menuAnchorEl}
                            open={Boolean(menuAnchorEl) && menuSelectedId === row.id}
                            onClose={handleMenuClose}
                          >
                            {row.hasOpenInvoices && row.moveOutDate !== '' ? (
                              <MenuItem disabled>Tenant with open invoices</MenuItem>
                            ) : isLoading ? (
                              <MenuItem>Loading ...</MenuItem>
                            ) : selected.length > 1 ? (
                              <MenuItem
                                onClick={() => {
                                  setDeleteAction(() => handleDeleteSubscribers)
                                  setOpenDelete(true)
                                  handleMenuClose()
                                }}
                              >
                                Delete Tenants
                              </MenuItem>
                            ) : (
                              <>
                                {!row.isBillingContact && stripeConnect && (
                                  <MenuItem
                                    onClick={() => {
                                      handleDialogBillingContact(row)
                                    }}
                                    disabled={canEdit}
                                  >
                                    Select Tenant for Invoicing
                                  </MenuItem>
                                )}
                                <MenuItem
                                  onClick={() => {
                                    handleOpenEdit(row.id)
                                    handleMenuClose()
                                  }}
                                  disabled={canEdit}
                                >
                                  Edit Tenant
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    setDeleteAction(
                                      () => () =>
                                        handleDeleteSubscriber(row.id, { UnitName: row.unit_name, UnitID: row.unit_id })
                                    )
                                    setOpenDelete(true)
                                    handleMenuClose()
                                  }}
                                  disabled={canEdit}
                                >
                                  Delete Tenant
                                </MenuItem>
                              </>
                            )}
                          </Menu>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={8} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onRowsPerPageChange={handleChangeRowsPerPage}
            component={'div'}
            onPageChange={handleChangePage}
          />
        </Paper>
        <FormDialogSubscriber
          open={isAddFormOpen}
          onClose={() => setIsAddFormOpen(false)}
          type="ADD"
          onSubmit={(body, unitInfo) => handleAdd(body, unitInfo)}
          units={units}
          addTenant={setIsAddFormOpen}
        />

        <FormDialogSubscriber
          open={isEditFormOpen}
          onClose={() => setIsEditFormOpen(false)}
          type="EDIT"
          onSubmit={(body, unitInfo) => handleEdit(body, unitInfo)}
          units={units}
          editData={subscriberToEdit}
          addTenant={setIsAddFormOpen}
        />
        <FormBulkSubscriber
          onClose={() => setIsBulkFormOpen(false)}
          open={isBulkFormOpen}
          units={units}
          onSubmit={(body, type) => handleAddBulk(body, type)}
        />
      </div>

      <Dialog
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        sx={{ textAlign: 'center' }}
      >
        <Stack justifyContent={'center'} margin={5} gap={2}>
          <img src="/img/deleteIcon.png" height={56} width={56} style={{ margin: 'auto' }} alt="timeLogo" />
          <Typography fontSize={24} fontWeight={700} color={'#DC3545'}>
            Delete Tenant
          </Typography>
          <Typography fontSize={16} fontWeight={400}>
            Are you sure you want to delete this tenant? This will permanently delete the tenant and all associated
            information.
          </Typography>
        </Stack>

        <Stack direction={'row'} justifyContent={'center'} margin={1} gap={1}>
          <SWLoadingButtonOutlined onClick={() => setOpenDelete(false)}>Cancel</SWLoadingButtonOutlined>
          <SWLoadingButton loading={isLoading} color="error" onClick={deleteAction}>
            Delete
          </SWLoadingButton>
        </Stack>
      </Dialog>

      <Dialog
        open={openConfirmDate}
        onClose={() => setOpenConfirmDate(false)}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        sx={{ textAlign: 'center' }}
      >
        <Stack justifyContent={'center'} margin={5} gap={2}>
          <Typography fontSize={24} fontWeight={700} color={'black'}>
            This change sets a tenant's move {typeDate === 'MoveIn' ? 'Move In' : 'Move Out'} date
          </Typography>
          <Typography fontSize={16} fontWeight={400}>
            Are you sure you want to change the date?
          </Typography>
        </Stack>

        <Stack direction={'row'} justifyContent={'center'} margin={1} gap={1}>
          <SWLoadingButtonOutlined onClick={() => setOpenConfirmDate(false)}>Cancel</SWLoadingButtonOutlined>
          <SWLoadingButton loading={isLoading} onClick={handleMoveOut}>
            Send
          </SWLoadingButton>
        </Stack>
      </Dialog>

      <Dialog
        open={openSubInvoice}
        onClose={() => setOpenSubInvoice(false)}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        sx={{ textAlign: 'center' }}
      >
        <Stack justifyContent={'center'} margin={5} gap={2}>
          <Typography fontSize={24} fontWeight={700} color={'black'}>
            {currentBillContact.needMoveOutDate ? 'Select Tenant Move In Date' : 'Select Tenant Move In Date'}
          </Typography>
          <Stack direction={'row'} gap={currentBillContact.needMoveOutDate ? 2 : 0} justifyContent={'center'}>
            <Stack>
              <Typography textAlign={'left'}>Move In Date:</Typography>
              <DatePicker
                placeholder="Move In Date"
                format={'MMMM D, YYYY'}
                value={tenantMoveInDate ? dayjs(tenantMoveInDate) : null}
                minDate={dayjs(currentBillContact.moveOutDate, dateFormat)}
                // maxDate={dayjs(format(today, 'yyyy-MM-dd'), dateFormat)}
                onChange={(value, dateString) => setTenantMoveInDate(moment(dateString).format(dateFormat))}
              />
            </Stack>
            {/* {currentBillContact.needMoveOutDate && (
              <Stack>
                <Typography textAlign={'left'}>{currentBillContact.name}:</Typography>
                <DatePicker
                  placeholder="Move Out Date"
                  format={'MMMM D, YYYY'}
                  value={tenantMoveOutDate ? moment(tenantMoveOutDate) : null}
                  // maxDate={dayjs(format(today, 'yyyy-MM-dd'), dateFormat)}
                  onChange={(value, dateString) => setTenantMoveOutDate(moment(dateString).format(dateFormat))}
                />
              </Stack>
            )} */}
          </Stack>
        </Stack>

        <Stack direction={'row'} justifyContent={'center'} margin={1} gap={1}>
          <SWLoadingButtonOutlined onClick={() => setOpenSubInvoice(false)}>Cancel</SWLoadingButtonOutlined>
          <SWLoadingButton loading={isLoading} onClick={handleUpdateBillingContact}>
            Send
          </SWLoadingButton>
        </Stack>
      </Dialog>
    </ErrorBoundary>
  )
}
