import { Box, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined'
import { IUnitBill } from '../View'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import React from 'react'
import moment from 'moment'
import NoRows from '@/components/tableNoRows'

export const BillHistoryTable = ({ data, timeZone }: { data: IUnitBill[]; timeZone: string }): JSX.Element => {
  dayjs.extend(utc)
  dayjs.extend(timezone)
  const [tz] = React.useState(timeZone === '' ? moment.tz.guess() : timeZone)
  console.log(data, 'data<<<<<<<')

  const addDaysToDate = (unixTimestamp: number, days: number, timeZone?: string | null) => {
    const effectiveTimeZone = timeZone || dayjs.tz.guess()

    const inputDate = new Date(unixTimestamp * 1000)
    inputDate.setDate(inputDate.getDate() + days)

    const year = inputDate.getFullYear()
    const month = String(inputDate.getMonth() + 1).padStart(2, '0')
    const day = String(inputDate.getDate()).padStart(2, '0')

    return dayjs
      .tz(`${year}-${month}-${day}`, effectiveTimeZone)
      .set('hours', 23)
      .set('seconds', 59)
      .set('minutes', 59)
      .set('milliseconds', 0)
      .valueOf()
  }

  const renderStatusColor = (status: string): string => {
    switch (status) {
      case 'not_created':
        return '#E0E0E0'
      case 'draft':
        return '#D0E9FF'
      case 'open':
        return '#FFEFD0'
      case 'paid':
        return '#DFF7DF'
      case 'uncollectable':
        return '#FFD6D6'
      case 'void':
        return '#E4D6FF'
      default:
        return '#E0E0E0'
    }
  }

  return (
    <div>
      <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ position: 'relative' }}>
                Amount
              </TableCell>
              <TableCell align="center" sx={{ position: 'relative' }}>
                Due
              </TableCell>
              {/* <TableCell align="center" sx={{ position: 'relative' }}>
                Email
              </TableCell> */}
              <TableCell align="center" sx={{ position: 'relative' }}>
                Billing Period
              </TableCell>
              <TableCell align="center" sx={{ position: 'relative' }}>
                Status
              </TableCell>
              <TableCell align="center" sx={{ position: 'relative' }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length !== 0 ? (
              data.map((e, i) => {
                return e.invoices.map((d) => {
                  return (
                    <TableRow>
                      <TableCell align="center">${d.stripe_total}</TableCell>
                      <TableCell align="center">
                        {dayjs(
                          addDaysToDate(e.billsSentTimestamp ? e.billsSentTimestamp : e.endDate, 21, timeZone)
                        ).format('DD/MM/YYYY')}
                      </TableCell>
                      {/* <TableCell align="center">some@email.com</TableCell> */}
                      <TableCell align="center">
                        {dayjs.unix(e.startDate).tz(tz).format('MMM D')} -{' '}
                        {dayjs.unix(e.endDate).tz(tz).format('MMM D')}
                      </TableCell>
                      <TableCell align="center">
                        <Box
                          sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: renderStatusColor(d.stripe_last_status),
                            color: d.stripe_last_status === 'paid' ? '#198754' : '#5A5A5A',
                            borderRadius: '16px',
                            fontWeight: 'bold',
                            padding: '4px 12px',
                            fontSize: '0.875rem',
                            textTransform: 'uppercase',
                            boxShadow: 1,
                            whiteSpace: 'nowrap'
                          }}
                        >
                          {d.stripe_last_status.replace('_', ' ').toUpperCase()}
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          disabled={d.stripe_invoice_pdf_url === null ? true : false}
                          onClick={() => (window.location.href = d.stripe_invoice_pdf_url!)}
                        >
                          <SimCardDownloadOutlinedIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })
              })
            ) : (
              <TableRow>
                <TableCell colSpan={6} sx={{ marginTop: 2 }}>
                  <NoRows title="No Rows" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}
